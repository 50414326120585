var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mew-component--app-mewtopia-block" }, [
    _c(
      "div",
      { staticClass: "desktop-content d-none d-lg-block" },
      [
        _c("v-container", [
          _c(
            "div",
            {
              directives: [
                {
                  name: "lazy",
                  rawName: "v-lazy:background-image",
                  value: _vm.backgroundImg,
                  expression: "backgroundImg",
                  arg: "background-image",
                },
              ],
              staticClass:
                "border-radius--10px card-content d-flex align-center justify-space-between",
            },
            [
              _c("div", [
                _c("h1", { staticClass: "white--text" }, [
                  _vm._v(_vm._s(_vm.$t("home.mewtopia-block.title"))),
                ]),
                _c("p", { staticClass: "white--text mt-3" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("home.mewtopia-block.text")) + " "
                  ),
                ]),
              ]),
              _c("mew-button", {
                attrs: {
                  "btn-link": "https://www.mewtopia.com/",
                  "has-full-width": false,
                  title: _vm.$t("home.mewtopia-block.button-text"),
                  "btn-style": "outline",
                  "color-theme": "white",
                  "btn-size": "xlarge",
                },
              }),
            ],
            1
          ),
        ]),
      ],
      1
    ),
    _c("div", { staticClass: "mobile-content d-block d-lg-none" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "lazy",
              rawName: "v-lazy:background-image",
              value: _vm.backgroundImg,
              expression: "backgroundImg",
              arg: "background-image",
            },
          ],
          staticClass: "card-content",
        },
        [
          _c(
            "v-sheet",
            {
              staticClass: "mx-auto",
              attrs: { color: "transparent", "max-width": "500px" },
            },
            [
              _c("div", { staticClass: "mb-8" }, [
                _c("h1", { staticClass: "white--text pb-2" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("home.mewtopia-block.mobile.title-one")) +
                      " "
                  ),
                ]),
                _c("h1", { staticClass: "white--text" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("home.mewtopia-block.mobile.title-two")) +
                      " "
                  ),
                ]),
                _c("p", { staticClass: "white--text mt-3" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("home.mewtopia-block.text")) + " "
                  ),
                ]),
              ]),
              _c("mew-button", {
                attrs: {
                  "btn-link": "https://www.mewtopia.com/",
                  "has-full-width": false,
                  title: _vm.$t("home.mewtopia-block.button-text"),
                  "btn-style": "outline",
                  "color-theme": "white",
                  "btn-size": "xlarge",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }